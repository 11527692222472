.form {
	.row + .row {
		margin-top: 0;
	}
	.form-floating {
		label {
			color: $gray-600;
			font-size: 16px;
			@include media-breakpoint-down(md) {
				font-size: 12px;
			}
		}
		> .form-control {
			color: $primary;
			height: auto;
			padding-top: 0.6rem;
		}
	}
	.form-check {
		padding-left: 0;
	}
	form .radiobox .form-check-wrapping-label {
		padding-left: 0 !important;
	}
	.form-check.radiobox {
		padding-left: 0;
	}
	//radiobox & Checkbox
	.radiobox,
	.checkbox {
		.form-check-label {
			display: block;
			position: relative;
			padding-left: 40px;
			margin-bottom: 12px !important;
			cursor: pointer;
			user-select: none;
			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}
			span {
				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 2px;
					border-radius: 50%;
					width: 26px;
					height: 26px;
					display: block;
					background: transparent;
					border: 2px solid $primary;
				}
				&:after {
					content: "";
					background-color: $primary2;
					position: absolute;
					left: 5px;
					top: 7px;
					border-radius: 50%;
					width: 16px;
					height: 16px;
					display: block;
					opacity: 0;
					transition: opacity 0.2s ease-in-out;
				}
			}
			input:checked ~ span {
				&:after {
					opacity: 1;
					transition: opacity 0.2s ease-in-out 0.2s;
				}
			}
			p {
				font-size: 15px;
			}
		}
	}

	.checkbox {
		.form-check-label {
			span {
				&::before,
				&::after {
					border-radius: 4px;
				}
				&::after {
					width: 20px;
					height: 20px;
					top: 5px;
					left: 3px;
				}
			}
		}
	}
	.form-select.is-invalid:not([multiple]):not([size]) {
		background-image: none;
	}
}

.form-control, .form-select {
		border-color: $primary;
		color: $primary;
}
.form-select {
	color: $primary;
	background-size: 20px 20px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z'/%3E%3C/svg%3E") !important;
	cursor: pointer;
	&:focus {
		box-shadow: none;
	}
	option {
		color: $primary;
	}
}