::marker {
	font-weight: $font-weight-bold;
}

// Text selection
::selection {
	color: $white;
	background-color: $primary2;
}

.bg-primary {
	::selection {
		color: $primary;
		background-color: $light;
	}
}

// Headlines
.h4,
h4 {
	line-height: 1.45;
}
.h5,
h5 {
	line-height: 1.5;
	font-weight: $font-weight-base;
}

h3,
.h3,
h4,
.h4,
h5,
.h5 {
	& + p,
	& + ul {
		margin-top: calc(var(--yt-paragraph-margin) * 0.6 * (-1));
	}
}

.btn {
	max-height: 50px;
	letter-spacing: 0.2px;
	padding-top: 12px;
	@include media-breakpoint-down(md) {
		margin-bottom: $spacer;
		font-size: 16px;
	}
	&:focus,
	&:focus-visible {
		box-shadow: none !important;
		background-color: $gray-600;
		color: $white;
	}
}
/* Button */
.btn-primary {
	@include button-variant(
		$primary,
		$primary,
		$color: $white,
		$hover-background: $primary2,
		$hover-color: $primary,
		$active-background: $primary2,
		$active-color: $primary,
		$disabled-background: $gray-500,
		$disabled-color: $white
	);
}

.btn-primary2 {
	@include button-variant(
		$primary2,
		$primary2,
		$color: $primary,
		$hover-background: $primary,
		$hover-color: $primary2,
		$active-background: $primary,
		$active-color: $primary2,
		$disabled-background: $gray-500,
		$disabled-color: $white
	);
	&.active {
		background-color: $primary;
		color: $primary2;
	}
}

*:disabled,
[aria-disabled="true"] {
	background-color: $gray-500;
	color: $white;
	border-color: transparent;
}

// Headlines
.subline {
	font-weight: $font-weight-bold;
	font-size: 18px;
}

.header-group {
	.subline {
		margin-bottom: $spacer;
		@include media-breakpoint-down(md) {
			margin-bottom: 0;
		}
	}

	&.is-h3,
	&.is-h4,
	&.is-h5 {
		.subline {
			margin-bottom: $spacer * 2;
		}
		& + p {
			margin-top: $spacer * 0.5;
		}
	}

	&[class*="pb-"] {
		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

// Links
a:not([class])
	{
	color: $primary;
	background-image: linear-gradient($primary, $primary);
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100% 2px;
	background-clip: border-box;
	transition: background-image 0.4s, color 0.2s;
	width: max-content;
	&:hover {
		color: $primary2;
		background-image: linear-gradient($primary2, $primary2);
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
	}
}

.download-link {
	color: $primary;
	transition: color 0.04s ease-in;
	padding-bottom: 6px;
	svg {
		width: 25px;
    height: 25px;
    margin-right: 6px;
	}
	&:hover, &:active, &.active {
		color: $primary2;
		background-image: linear-gradient($primary2, $primary2);
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100% 2px;
		svg {
			fill: $primary2;
		}
	}
	@include media-breakpoint-down(sm) {
		font-size: 14px;
	}
}

// Lists
ul {
	margin: 0 0 $paragraph-margin-bottom 0;
	padding: 0;
	li {
		list-style: none;
		position: relative;
		padding: 0 0 0 $spacer * 1;
		margin-bottom: 0.5rem;
		&:before {
			content: "";
			height: 6px;
			width: 6px;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 0.7rem;
			background-color: $primary;
		}
	}
}
ol:not(.breadcrumb) {
	margin: 0 0 $paragraph-margin-bottom 0;
	padding: 0;
	counter-reset: orderedlist;
	li {
		list-style: none;
		position: relative;
		padding-left: 2.75rem;
		margin-bottom: 0.5rem;
		&:before {
			counter-increment: orderedlist;
			content: counter(orderedlist);
			position: absolute;
			top: 0;
			left: -10px;
			text-align: right;
			width: 2rem;
			color: $primary;
		}
	}
}

//Table
.table.table-hover {
	tr {
		vertical-align: middle;
		padding-left: $spacer;
		padding-right: $spacer;
		&:hover {
			background-color: $gray-500;
			.text-btn {
				background-color: $gray-600;
				border-color: $gray-600;
			}
		}
	}
}

//Material icons
.material-icons{
  vertical-align: middle;
  padding-bottom: 6px;
	font-weight: $font-weight-base;
	margin-right: 10px;
	width: 48px;
	height: 48px;
  &::after {
    content: attr(data-icon);
    font-size: 48px;
   
		width: 100%;
    display: inline-block;
  }
}