// Eigene Variablen
$section-margin:                   20px;
$section-margin-xs:                20px;
$section-margin-sm:                40px;
$section-margin-md:                50px;
$section-margin-lg:                80px;

$header-height:             96px;







