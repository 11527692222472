// Colors in Sections
.bg- {
	&light {
		&,
		[class*="bg-"] &,
		[class*="bg-"] [class*="bg-"] & {
			background-color: $light;
			color: $body-color;
			a {
				color: $link-color;
			}
			.text-primary {
				color: $primary !important;
			}
			.text-secondary {
				color: $secondary !important;
			}
			ul {
				li {
					&:before {
						color: $secondary;
					}
				}
			}
			ol:not(.breadcrumb) {
				li {
					&:before {
						color: $secondary;
					}
				}
			}
			.btn-primary {
				color: $white;
				background-color: $secondary;
				border-color: $secondary;
				&:is(:hover, :active, :focus-visible) {
					background-color: transparent;
					border-color: $secondary;
					color: $secondary;
				}
			}
			.btn-secondary {
				color: $secondary;
				background-color: $white;
				border-color: $secondary;
				&:is(:hover, :active, :focus-visible) {
					background-color: $secondary;
					border-color: tint-color($secondary, $btn-hover-bg-tint-amount);
					color: $white;
				}
			}
			.table {
				color: $primary;
				thead,
				tbody,
				tfoot,
				tr,
				td,
				th {
					border-color: $primary;
				}
				caption {
					color: rgba($primary, 0.5);
				}
				&.table-striped {
					> tbody {
						> tr:nth-of-type(2n + 1) {
							> * {
								background-color: rba($primary, 0.25);
								color: $primary;
							}
						}
					}
				}
				&.table-hover {
					> tbody {
						> tr:hover {
							> * {
								background-color: rba($primary, 0.5);
								color: $primary;
							}
						}
					}
				}
			}
			// Filter
			.dropdown {
				&.dropdown-filter {
					.btn-filter {
						color: $primary;
						background-color: $white;
						.icon {
							color: $secondary;
							background-color: $primary;
						}
					}
				}
			}

			.form {
				color: $primary;
			}
			.form-text {
				color: rgba($primary, 0.5);
			}
			.form-control,
			.form-check-input,
			.form-select {
				border-color: $primary;
			}
			.form-select {
				color: $primary;
				background-image: escape-svg($form-select-indicator);
				option {
					color: $primary;
				}
			}
			.form-control {
				color: $primary;
			}
			.nav {
				.nav-item {
					color: $primary;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.quote-caption {
				color: $secondary;
			}
		}
	}
	&primary {
		&,
		[class*="bg-"] &,
		[class*="bg-"] [class*="bg-"] & {
			background-color: $primary;
			color: $lightGreen;
			h2,p{
					color: $lightGreen;
			}
			a {
				color: $primary2;
				&:hover, &:active {
					color: $lightGreen;
					background-image: linear-gradient($lightGreen, $lightGreen);
				}
			}

			.text-primary {
				color: $primary2 !important;
			}

			ul {
				li {
					&:before {
						background-color: $lightGreen;
					}
				}
			}

			ol:not(.breadcrumb) {
				li {
					&:before {
						color: $lightGreen;
					}
				}
			}
			.btn-primary {
				color: $primary;
				background-color: $lightGreen;
				&:is(:hover, :active, :focus-visible) {
					background-color: $primary2;
					color: $white;
				}
			}
			.btn-primary2 {
				color: $primary;
				background-color: $primary2;
				&:is(:hover, :active, :focus-visible) {
					background-color: $lightGreen;
					color: $primary2;
				}
			}

			.download-link {
				color: $primary2;
				svg {
					fill: $primary2;
				}
				&:hover, &:active, &.active {
					color: $lightGreen;
					background-image: linear-gradient($lightGreen, $lightGreen);
					svg {
						fill: $lightGreen;
					}
				}
			}
			.table {
				color: inherit;
				thead,
				tbody,
				tfoot,
				tr,
				td,
				th {
					border-color: $lightGreen;
				}
				caption {
					color: $lightGreen;
				}
				&.table-striped {
					> tbody {
						> tr:nth-of-type(2n + 1) {
							> * {
								background-color: tint-color(
									$primary,
									$btn-hover-bg-tint-amount * 0.5
								);
								color: $lightGreen;
							}
						}
					}
				}
			}
			// Filter
			.dropdown {
				&.dropdown-filter {
					.btn-filter {
						color: $primary;
						background-color: $white;
						.icon {
							color: $primary;
							background-color: $secondary;
						}
					}
				}
			}
			.form-control,
			.form-check-input,
			.form-select {
				border-color: $secondary;
			}
			.form-select {
				color: $gray-500;
				background-image: escape-svg(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")
				);
				option {
					color: $gray-500;
				}
			}
			.form-floating > label {
				color: $gray-500;
			}
			form .form-check-label span::before {
				background-color: inherit;
				border-color: $lightGreen !important;
			}
			form .form-check-label span::after {
				color: $primary2;
			}
			form .radiobox .form-check-label span::before, form .radiobox .form-check-wrapping-label span::before {
				border-color: $lightGreen;
			}
			.nav {
				.nav-item {
					color: $lightGreen;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.form-text{
				color: rgba(255,255,255,.5)
			}
			.quote-caption {
				color: $primary2;
			}
		}
	}
	&primary2 {
		&,
		[class*="bg-"] &,
		[class*="bg-"] [class*="bg-"] & {
			color: $primary;
			background-color: $primary2;
			a {
				color: $primary;
				&:hover, &:active, &.active {
					color: $lightPetrol;
					background-image: linear-gradient($lightPetrol, $lightPetrol);
				}
			}
			.table {
				color: $primary;
			}
			.text-primary {
				color: $primary !important;
			}
			.text-primary2 {
				color: $lightGreen !important;
			}
			.text-muted {
				color: rgba($white, 0.75) !important;
			}
			ul {
				li {
					&:before {
						background-color: $primary;
					}
				}
			}
			ol:not(.breadcrumb) {
				li {
					&:before {
						color: $primary;
					}
				}
			}
			.btn-primary {
				color: $white;
				background-color: $primary;
				&:is(:hover, :active, :focus-visible) {
					background-color: $lightGreen;
					color: $primary;
				}
			}
			.btn-primary2 {
				color: $primary2;
				background-color: $lightGreen;
				&:is(:hover, :active, :focus-visible) {
					background-color: $primary;
					color: $white;
				}
			}
			.table {
				color: $white;
				thead,
				tbody,
				tfoot,
				tr,
				td,
				th {
					border-color: $primary;
					color: $primary;
				}
				caption {
					color: rgba($white, 0.5);
				}
				&.table-striped {
					> tbody {
						> tr:nth-of-type(2n + 1) {
							> * {
								background-color: shade-color(
									$secondary,
									$btn-hover-bg-shade-amount * 0.15
								);
								color: $primary;
							}
						}
					}
				}
				&.table-hover {
					> tbody {
						> tr:hover {
							> * {
								background-color: shade-color(
									$secondary,
									$btn-hover-bg-shade-amount * 0.3
								);
								color: $primary;
							}
						}
					}
				}
			}
			.download-link {
				&:hover, &:active, &.active {
					color: $lightPetrol;
					svg {
						fill: $lightPetrol;
					}
				}
			}
			// Filter
			.dropdown {
				&.dropdown-filter {
					.btn-filter {
						color: $primary;
						background-color: $white;
						.icon {
							color: $secondary;
							background-color: $primary;
						}
					}
				}
			}
			.form-control,
			.form-check-input,
			.form-select {
				border-color: $primary;
			}
			.form-select {
				color: $gray-500;
				background-image: escape-svg(
					url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>")
				);
				option {
					color: $gray-500;
				}
			}
			.form-floating > label {
				color: $gray-500;
			}
			form .form-check-label span::before {
				background-color: inherit;
				border-color: $primary !important;
			}
			form .form-check-label span::after {
				color: $primary;
				background-color: $primary !important;
			}
			form .radiobox .form-check-label span::before, form .radiobox .form-check-wrapping-label span::before {
				border-color: $primary;
			}
			.nav {
				.nav-item {
					color: $primary;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.form-text{
				color: rgba(255,255,255,.5)
			}
			.quote-caption {
				color: $primary;
			}
		}
	}
}
