/* Small container */
.container {
	//max-width: 98%;
	&.container-75 {
		max-width: 75%;

		@include media-breakpoint-down(lg) {
			max-width: 96%;
		}
	}
}

/* Section padding */
section {
	padding-bottom: $section-margin-xs;
	padding-top: $section-margin-xs;
	
	@include media-breakpoint-up(md) {
		padding-bottom: $section-margin-md;
		padding-top: $section-margin-md;
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: $section-margin-lg;
		padding-top: $section-margin-lg;
	}
}

/* Distance between two rows */
.row + .row,
.content-row + .content-row {
	margin-top: $section-margin-md;

	@include media-breakpoint-down(sm) {
		margin-top: calc($section-margin-md / 2);
	}

	@include media-breakpoint-down(md) {
		margin-top: calc($section-margin-md / 2);
	}
	@include media-breakpoint-down(lg) {
		margin-top: calc($section-margin-lg / 2);
	}
}
