* {
	scrollbar-color: $secondary $primary;
}
:root {
	--app-height: 100vh;
}

main {
	padding-top: 88px;
	overflow: hidden;
	// margin: 0 25px 25px 25px;
	min-height: 100vh;
	@include media-breakpoint-down(xl) {
		padding-top: 80px;
	}
	@include media-breakpoint-down(md) {
		margin: 0;
	}
	p, a {
		@include media-breakpoint-down(sm) {
			font-size: 16px;
		}
	}
}

// Default section padding
.py-section {
	padding-top: $section-margin;
	padding-bottom: $section-margin;

	@include media-breakpoint-up("sm") {
		padding-top: $section-margin-sm;
		padding-bottom: $section-margin-sm;
	}

	@include media-breakpoint-up("md") {
		padding-top: $section-margin-md;
		padding-bottom: $section-margin-md;
	}

	@include media-breakpoint-up("lg") {
		padding-top: $section-margin-lg;
		padding-bottom: $section-margin-lg;
	}
}
.mt-section {
	margin-top: $section-margin !important;

	@include media-breakpoint-up("sm") {
		margin-top: $section-margin-md !important;
	}
}
.mb-section {
	margin-bottom: $section-margin !important;

	@include media-breakpoint-up("sm") {
		margin-bottom: $section-margin-md !important;
	}
}

//Page Animation
main > section:first-child.js-scroll{
  opacity: 1;
  animation: none;
  transition: none;
}
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
  animation: fade-in-bottom 0.8s ease-in-out both;
}

@keyframes fade-in-bottom {
  0% {
   -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

//Fancybox (Video)
.is-using-mouse .fancybox__content {
	opacity: 0;
	animation: fadeIn 0.2s ease-out both;
}
.fancybox__content {
	border-radius: 16px;
	
  &.fancybox__content-video {
    padding: 0;
  }
	> .carousel__button.is-close {
		width: 16px;
		height: 16px;
		top: -25px;
		right: -18px;
		color: $primary;
		border-radius: 50%;
		border: 1px solid $primary;

		svg {
			width: 8px;
			height: 8px;
			filter: none;
		}

		@include media-breakpoint-down(lg){
			right: 0;
		}
	}
	.fancybox__html5video, .fancybox__iframe {
		border-radius: 16px;
	}
}
.fancybox__backdrop {
	background-color: $white;
}

@keyframes fadeIn {
	from {
		opacity: 0;
			transform: translate3d(0, -5%, 0);
	}
	to {
		opacity: 1;
	
    transform: translate3d(0, -10%, 0);
	}
}

//Downloads
.downloads {
	.table-hover > tbody > tr:hover > * {
		color: inherit;
	}
}

.ce-uploads {
	li {
		&::before {
			content: none;
		}
		a {
			color: $primary;
		}
		.row {
			padding: 0.6rem 0;
			&:hover {
				background-color: $gray-500;
				.text-btn {
					background-color: $gray-600;
					border-color: $gray-600;
				}
			}
		}
	}	
}

//Breadcrumb
.breadcrumb {
	justify-content: center;
	li {
		a {
			color: $primary !important;
			background-image: none !important;
			display: inline-block !important;
			&:hover {
				color: $secondary !important;
			}
		}
		&.active {
			a {
				color: $secondary !important;
			}
		}
	}
}